import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';

export type FeedResponse = {
  items: Array<{
    id: string;
    title: string;
    url: string;
    date_modified: string;
  }>;
};

const FEED_URL = 'https://rocket.net/blog/category/product-updates/feed/json/';

export const useDashboardFeed = (): UseQueryResult<AxiosResponse<FeedResponse>, unknown> => {
  return useQuery({
    queryKey: ['dashboard-feed'],

    queryFn: async () => await axios.get<FeedResponse>(FEED_URL),
  });
};
